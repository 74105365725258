<template>

	<a-card :bordered="false" class="widget-1">

        <a-row>

            <a-col :span="24" :xl="12" class="mb-24">

                <a-form v-if="channel_data.channel_provider=='twilio'">
            <a-form-item label="Twilio API Key">
                <a-input
                    v-model="channel_data.api_key"
                    :placeholder="'Twilio API Key'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>
            

            <a-form-item label="Twilio API Key Secret">
                <a-input
                    v-model="channel_data.api_key_secret"
                    :placeholder="'Twilio API Key Secret'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>

            <a-form-item label="Twilio Number">
                <a-input
                    v-model="channel_data.twilio_number"
                    :placeholder="'Twilio Number'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>

            <a-form-item label="Twilio App SID">
                <a-input
                    v-model="channel_data.twiml_app_sid"
                    :placeholder="'Twilio App SID'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>

            <a-form-item label="Twilio Account SID">
                <a-input
                    v-model="channel_data.account_sid"
                    :placeholder="'Twilio Account SID'"
                    :disabled="false"
                >
                </a-input>
            </a-form-item>

           
        </a-form>

            </a-col>


        </a-row>
        
      
		
	</a-card>

</template>

<script>

	export default ({
		props: {
			channel_data: {
				type: Object,
				default: {},
			},
			status: {
				type: String,
				default: "success",
			},
		},
		data() {
			return {
			}
		},
	})

</script>