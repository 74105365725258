<template>
    <div
        :class='["message", { dark }]'
    style="width: 90%; margin: 9px;">
      <h5>{{ author }}</h5>
      {{ text }}

        <div v-if="files">
            <a-row>
            <a-col :span="12" v-for="file in files" :key="file.url">
                <div v-if="isImage(file)">
                <a :href="file.url" target="_blank">
                    <img :src="file.url" style="max-width: 100%; max-height: 300px;"/>
                </a>
                </div>

                <div v-if="isVideo(file)">

                <video :src="file.url" controls style="max-width: 100%; max-height: 300px;">
                    Your browser does not support the video tag.
                </video>
                </div>

                <div v-if="isAudio(file)">
                <audio :src="file.url" controls>
                    Your browser does not support the audio element.
                </audio>
                </div>
                <a-icon type="file" />
                {{ file.name }}
            
            </a-col>
            </a-row>
        </div>

        <div style="text-align: right; margin-top: 1rem;">
            <small>{{ relativeTime(timestamp) }}</small>
        </div>
    </div>
  </template>

<script>
export default {
  name: 'Message',
  props: {
    text: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
    },
    timestamp: {
      type: Number,
      default: 0,
    },
}
,methods: {
        relativeTime(timestamp) {
            const date = new Date(timestamp * 1000);

            const now = new Date();

            const diff = now - date;

            const seconds = Math.floor(diff / 1000);

            if (seconds < 60) {
                return `${seconds} seconds ago`;
            }

            const minutes = Math.floor(seconds / 60);

            if (minutes < 60) {
                return `${minutes} minutes ago`;
            }

            const hours = Math.floor(minutes / 60);

            if (hours < 24) {
                return `${hours} hours ago`;
            }

            const days = Math.floor(hours / 24);

            if (days < 7) {
                return `${days} days ago`;
            }

            const weeks = Math.floor(days / 7);

            if (weeks < 4) {
                return `${weeks} weeks ago`;
            }
        },
        isImage(file) {
            return file.type && file.type.startsWith('image');
        },
        isVideo(file) {
            return file.type && file.type.startsWith('video');
        },
        isAudio(file) {
            return file.type && file.type.startsWith('audio');
        },
    }
}
</script>

<style scoped>
.message {
  background: #e7e7e7;
  border-radius: 10px;
  padding: 1rem;
  width: fit-content;
}

.message.dark {
  background: #e9eaf6;
}

h5 {
  margin: 0 0 .5rem 0;
}
</style>