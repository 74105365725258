<template>

    <a-card :bordered="false" class="widget-1">

        <a-row>

            <a-col :span="24" :xl="12" class="mb-24">


                <a-form v-if="channel_data.channel_provider == 'whabiz'">

 
                    <a-form-item label="Whatsapp Number">
                        <a-input v-model="channel_data.wa_number" :placeholder="'Whatsapp Number'" :disabled="false">
                        </a-input>
                    </a-form-item>

                    <a-form-item label="Whatsapp Business ID">
                        <a-input v-model="channel_data.waba_id" :placeholder="'Whatsapp Business ID'" :disabled="false">
                        </a-input>
                    </a-form-item>

                    <a-form-item label="Whatsapp Phone ID">
                        <a-input v-model="channel_data.number_id" :placeholder="'Whatsapp Phone ID'" :disabled="false">
                        </a-input>
                    </a-form-item>

                    <a-form-item label="Whatsapp Business Token">
                        <a-input v-model="channel_data.waba_token" :placeholder="'Whatsapp Business Token'" :disabled="false">
                        </a-input>
                    </a-form-item>


                    <a-form-item label="Messages Webhook">
                        <a-input v-model="channel_data.messages_webhook" :placeholder="'Messages Webhook url'" :disabled="false">
                        </a-input>
                    </a-form-item>


                    <a-button type="secondary" block style="margin-left: 1px;" @click="requestAddFbAccount">
                    <i class="fa fa-facebook" style="margin-right: 7px;"></i>{{ 'Log In With Facebook' }}
                    </a-button>
                
                </a-form>

                <a-form v-if="channel_data.channel_provider == 'twilio'">


                    <a-form-item label="Twilio Number">
                        <a-input v-model="channel_data.twilio_number" :placeholder="'Twilio Number'" :disabled="false">
                        </a-input>
                    </a-form-item>



                    <a-form-item label="Twilio Account SID">
                        <a-input v-model="channel_data.account_sid" :placeholder="'Twilio Account SID'"
                            :disabled="false">
                        </a-input>
                    </a-form-item>


                    <a-form-item label="Twilio Account Token">
                        <a-input v-model="channel_data.twilio_token" :placeholder="'Twilio Token'" :disabled="false">
                        </a-input>
                    </a-form-item>


                </a-form>

            </a-col>


        </a-row>



    </a-card>

</template>

<script>

import { getWhapiLoginLink } from '../../services/v1/services';

import { currentToken } from '../../services/v1/data_methods';

export default ({
    props: {
        channel_data: {
            type: Object,
            default: {},
        },
        status: {
            type: String,
            default: "success",
        },
        channelId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
        }
    },
    methods: {
        async requestAddFbAccount() {
            console.log('requestAddFbAccount');
            let link = getWhapiLoginLink();

            link = link + '?utok=' + currentToken() + '&channel=' + this.channelId;
            console.log('link', link);

            //opne the link in new tab
            window.open(link, '_blank');
        },
    }
})

</script>