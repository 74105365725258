<script>
export default {
  name: 'ChatBox',
  data: () => ({

    // To keep the state of the input content
    text: ''
  }),
  methods: {

    // We will call this when the form is submitted
    onSubmit(event) {

      console.log(this.text)

      // This fires an event which we will handle
      // in the parent component
      this.$emit("submitChat", event, this.text);
      this.text = '';
    }
  }
}
</script>

<template>
  <a-row>

    <a-form class='chat-box' @submit='onSubmit($event)'>
      <a-input
          v-model='text'
          placeholder='Write a message'
          type='text'
      />
      <a-button type="primary" html-type="submit" :disabled='text === ""'>Send</a-button>
    </a-form>


  </a-row>
    
  </template>


<style scoped>
.chat-box {
  width: 100%;
  display: flex;
}

input {
  width: min(100%, 20rem);
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;
}

button:disabled {
  opacity: 0.5;
}
</style>
